import axios from "@/app/axios.js"

if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initCategoryCustom)
} else {
    initCategoryCustom()
}

function initCategoryCustom() {
    const categoryDetails = document.getElementById("category-details")
    if (!categoryDetails) return

    const {
        category,
        software,
        activetype: activeType,
        url,
        countcontent: countContent,
        countpackages: countPackages
    } = categoryDetails.dataset

    const switchToPackages = document.getElementById("switch-to-packages")
    const switchToContent = document.getElementById("switch-to-content")
    const switchCategoryType = document.getElementById("switch-category-type")
    const switchSoftwareType = document.getElementById("switch-software-type")

    if (category) {
        switchToPackages?.addEventListener(
            "click",
            () => (window.location.href = `${url}?type=packages#category-details`)
        )
        switchToContent?.addEventListener(
            "click",
            () => (window.location.href = `${url}?type=content#category-details`)
        )
        switchCategoryType?.addEventListener("click", () => {
            window.location.href = `${url}?type=${activeType === "packages" ? "content" : "packages"}#category-details`
        })
    }

    if (software) {
        switchToPackages?.addEventListener(
            "click",
            () => (window.location.href = `${url}?type=packages#software-details`)
        )
        switchToContent?.addEventListener(
            "click",
            () => (window.location.href = `${url}?type=content#software-details`)
        )
        switchSoftwareType?.addEventListener("click", () => {
            window.location.href = `${url}?type=${activeType === "packages" ? "content" : "packages"}#software-details`
        })
    }

    const perPage = 24
    const perPageContent = 100

    let nextPage = 2
    let hasMoreContent = parseInt(countContent) > (nextPage - 1) * perPageContent
    let hasMorePackages = parseInt(countPackages) > (nextPage - 1) * perPage - 12

    function loadContent() {
        const button = document.getElementById("category-content-show-more-button")
        if (!button) return

        button.classList.add("button-loading")
        document
            .querySelectorAll(".category-content-show-more-button .button-loading-outer")
            .forEach((el) => (el.style.display = "block"))

        axios.get(`${url}?type=content_more_${nextPage}`).then((resp) => {
            nextPage++
            hasMoreContent = parseInt(countContent) > (nextPage - 1) * perPageContent

            const contentHidden = document.getElementById("category-content-hidden")
            contentHidden.innerHTML += resp.data
            contentHidden.style.display = "block"

            button.classList.remove("button-loading")
            document
                .querySelectorAll(".category-content-show-more-button .button-loading-outer")
                .forEach((el) => (el.style.display = "none"))

            if (hasMoreContent) {
                window.buttonAddContentFn("#category-content-hidden ")
                window.addEventListener("scroll", handleScrollEventContent)
            } else {
                document.getElementById("category-content-show-more").style.display = "none"
            }
        })
    }

    function loadPackages() {
        const button = document.getElementById("category-packages-show-more-button")
        if (!button) return

        button.classList.add("button-loading")
        document
            .querySelectorAll(".category-packages-show-more-button .button-loading-outer")
            .forEach((el) => (el.style.display = "block"))

        axios.get(`${url}?type=packages_more_${nextPage}`).then((resp) => {
            nextPage++
            hasMorePackages = parseInt(countPackages) > (nextPage - 1) * perPage - 12

            const packagesHidden = document.getElementById("category-packages-hidden")
            packagesHidden.innerHTML += resp.data
            packagesHidden.style.display = "block"

            button.classList.remove("button-loading")
            document
                .querySelectorAll(".category-packages-show-more-button .button-loading-outer")
                .forEach((el) => (el.style.display = "none"))

            if (hasMorePackages) {
                window.buttonAddPackageFn("#category-packages-hidden ")
                window.addEventListener("scroll", handleScrollEventPackages)
            } else {
                document.getElementById("category-packages-show-more").style.display = "none"
            }
        })
    }

    function handleScrollEventContent() {
        const button = document.getElementById("category-content-show-more-button")
        if (button.getBoundingClientRect().top < window.innerHeight * 2) {
            loadContent()
            window.removeEventListener("scroll", handleScrollEventContent)
        }
    }

    function handleScrollEventPackages() {
        const button = document.getElementById("category-packages-show-more-button")
        if (button.getBoundingClientRect().top < window.innerHeight * 2) {
            loadPackages()
            window.removeEventListener("scroll", handleScrollEventPackages)
        }
    }

    if (document.getElementById("category-content-show-more-button")) {
        window.addEventListener("scroll", handleScrollEventContent)
    }
}
